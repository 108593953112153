import { graphql, PageProps } from 'gatsby';
import React from 'react';

import { Box } from '../components/Core';
import { HERO_TYPE } from '../components/Hero/constants';
import Hero from '../components/Hero/Hero';
import { BUTTON_STYLES } from '../constants/ui';
import { createImageWithArtDirection } from '../utils/serializeImageForImageComponent';

const NotFoundPage: FCWithClassName<PageProps<Queries.Page404Query>> = (props) => {
  const {
    data: {
      wpConfiguration: { configuration: page404Data },
    },
  } = props;
  const cta = {
    internalLink: { id: 'home', uri: '/' },
    style: BUTTON_STYLES.SOLID,
    titre: page404Data.texteBoutonRetourALaccueil,
  };

  const heroImageWithArtDirection = createImageWithArtDirection({
    desktopImage: page404Data.imageHeroDesktop,
    mobileImage: page404Data.imageHeroMobile,
  });

  return (
    <Box className="relative z-0 min-h-screen p-4 pt-24 lg:p-10">
      <Hero
        couleurTitre={null}
        backgroundImage={heroImageWithArtDirection}
        heading="404"
        tagline={page404Data.tagline}
        type={HERO_TYPE.STATIC}
        ctas={[cta]}
        className="px-4 py-0 max-w-none lg:px-10"
      />
    </Box>
  );
};

export const query = graphql`
  query Page404 {
    wpConfiguration(id: { eq: "cG9zdDoyMTI1" }) {
      id
      configuration {
        imageHeroDesktop {
          altText
          localFile {
            ...HeroImage
          }
          sourceUrl
        }
        imageHeroMobile {
          altText
          localFile {
            ...MobileHeroImage
          }
          sourceUrl
        }
        tagline
        texteBoutonRetourALaccueil
      }
    }
  }
`;

export default NotFoundPage;
